import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getValueFromKey',
  standalone: true,
})
export class GetValueFromKeyPipe implements PipeTransform {
  transform(items: { key: string | number; value: string }[], key: string | number): string {
    return items.find((option) => option.key === key)?.value || '';
  }
}
