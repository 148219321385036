<fieldset class="mt-4" *transloco="let t; scope: 'core'">
  <div>
    <label *ngIf="label" [class.text-error]="hasError" class="block text-sm font-bold mb-1"
      >{{ label }}
      <ng-container *ngIf="required">*</ng-container>
    </label>
    <div class="mt-1 relative w-full whitespace-normal">
      <button
        (click)="toggleIsOpen()"
        type="button"
        class="js-select bg-white relative w-full border border-gray-500 shadow-sm pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary rounded sm:text-sm"
      >
        <span [ngClass]="value ? '' : 'text-gray-700'" class="js-select-text block truncate pointer-events-none whitespace-normal">
          {{
            nullValue && value === null
              ? nullValue
              : value
                ? translateValue
                  ? t(options | getValueFromKey: value)
                  : (options | getValueFromKey: value)
                : placeholder
          }}
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </span>
      </button>
      <ul
        [class.hidden]="!isOpen"
        class="js-select-options absolute z-70 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <li
          *ngIf="clearable"
          (click)="clearAll.emit(); resetValue(); toggleIsOpen()"
          class="js-option cursor-default select-none relative py-2 pl-3 pr-9 group hover:bg-primary text-gray-700"
        >
          <span class="js-option-text block group-hover:text-white group-hover:font-bold pointer-events-none">
            {{ t("core.select.clear-all") }}
          </span>
        </li>
        <li
          *ngIf="nullValue"
          (click)="changeOption(null); toggleIsOpen()"
          class="js-option cursor-default select-none relative py-2 pl-3 pr-9 group hover:bg-primary text-gray-700"
        >
          <span class="js-option-text block group-hover:text-white group-hover:font-bold pointer-events-none">
            {{ nullValue }}
          </span>
        </li>
        <li
          *ngFor="let option of options"
          (click)="changeOption(option.key); toggleIsOpen()"
          class="js-option cursor-default select-none relative py-2 pl-3 pr-9 group hover:bg-primary"
          [ngClass]="{ 'pointer-events-none opacity-50': option.hasOwnProperty('enabled') && !option.enabled }"
        >
          <span class="js-option-text block group-hover:text-white group-hover:font-bold pointer-events-none">
            {{ translateValue ? t(option.value) : option.value }}
          </span>
          <span
            [class.hidden]="option.key !== value"
            class="js-select-icon text-primary absolute inset-y-0 right-0 flex items-center pr-4 group-hover:text-white pointer-events-none"
          >
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </li>
      </ul>
    </div>
    <label *ngIf="additionalLabel" class="block text-xs my-2">
      {{ additionalLabel }}
    </label>
    <button
      *ngIf="enableEditButtonLabel"
      (click)="editButtonClick.emit()"
      class="block text-sm text-black hover:text-primary my-2 flex items-center"
    >
      {{ enableEditButtonLabel }}
      <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
        ></path>
      </svg>
    </button>
    <p *ngIf="hasError && errorText" class="font-primary text-sm text-error my-2">{{ errorText }}</p>
  </div>
</fieldset>
